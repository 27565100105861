import { useState } from "react";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "../services/firebase";

const useUpdateLastActivity = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [response, setResponse] = useState(null);

  const updateLastActivity = async (userId) => {
    if (!userId) {
      const error = "User ID is required.";
      setError(true);
      setErrorMessage(error);
      throw new Error(error);
    }

    try {
      setLoading(true);
      setError(false);
      setErrorMessage("");

      // Update the lastActivityDate field in the user's document
      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, {
        lastActivityDate: serverTimestamp(),
      });

      setResponse("Last activity date updated successfully!");
      return "Last activity date updated successfully!";
    } catch (error) {
      console.error("Error updating last activity date:", error);
      setError(true);
      setErrorMessage(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, errorMessage, response, updateLastActivity };
};

export default useUpdateLastActivity;
