import * as amplitude from "@amplitude/analytics-browser";
import { Box, Typography, alpha, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import PrimaryButton from "../global/button/PrimaryButton";

const Coaching = () => {
  useEffect(() => {
    amplitude.logEvent("Coaching_Visited");
  }, []);

  const theme = useTheme();
  return (
    <Box
      sx={{
        mt: { xs: "12px", sm: "26px" },
        display: "flex",
        flexDirection: "column",
        p: "12px 18px 28px",
        background: alpha(theme.palette.background.primary, 0.1),
        borderRadius: "16px",
      }}
    >
      <Typography
        sx={{
          fontFamily: theme.typography.fontFamily2,
          fontSize: theme.typography.headingBasic,
          color: theme.text.basic,
          fontWeight: theme.typography.headingBasicBold,
        }}
      >
        Get unstuck immediately
      </Typography>

      <Typography
        sx={{
          color: theme.text.basic,
          fontSize: { xs: "14px", sm: "16px" },
          marginTop: theme.margin.margin3,
          lineHeight: "18px",
        }}
      >
        Let’s pinpoint your 1 Small Change for Maximum Results.
      </Typography>
      <Typography
        sx={{
          color: theme.text.basic,
          fontSize: { xs: "14px", sm: "16px" },
          marginTop: theme.margin.margin2,
          lineHeight: "18px",
        }}
      >
        We’ll do this by understanding your:
      </Typography>

      <Box
        component={"ul"}
        sx={{
          listStylePosition: "outside",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
          marginLeft: "20px",
        }}
      >
        <Typography
          component={"li"}
          sx={{
            color: theme.text.basic,
            fontSize: { xs: "14px", sm: "16px" },
            marginTop: theme.margin.margin4,
            lineHeight: "16px",
          }}
        >
          <Typography
            component={"span"}
            sx={{
              fontWeight: theme.typography.headingMainBold,
              fontSize: { xs: "14px", sm: "16px" },
            }}
          >
            Body:
          </Typography>{" "}
          what is normal for you
        </Typography>
        <Typography
          component={"li"}
          sx={{
            color: theme.text.basic,
            fontSize: { xs: "14px", sm: "16px" },
            marginTop: theme.margin.margin4,
            lineHeight: "16px",
          }}
        >
          <Typography
            component={"span"}
            sx={{
              fontWeight: theme.typography.headingMainBold,
              fontSize: { xs: "14px", sm: "16px" },
            }}
          >
            Emotions:
          </Typography>{" "}
          your specific triggers and relationship with food
        </Typography>
        <Typography
          component={"li"}
          sx={{
            color: theme.text.basic,
            fontSize: { xs: "14px", sm: "16px" },
            marginTop: theme.margin.margin4,
            lineHeight: "16px",
          }}
        >
          <Typography
            component={"span"}
            sx={{
              fontWeight: theme.typography.headingMainBold,
              fontSize: { xs: "14px", sm: "16px" },
            }}
          >
            Beliefs:
          </Typography>{" "}
          what you believe about yourself and what’s real
        </Typography>
      </Box>
      <PrimaryButton
        component={"a"}
        onClick={() => amplitude.logEvent("Coaching_SetterCallClick")}
        href={
          "https://www.coachviva.com/resources/unstuck-call?utm_source=bfcalc"
        }
        target={"_blank"}
        sx={{ mt: "20px", mx: "auto", p: "24px", maxWidth: "320px" }}
      >
        Book Free UNStuck Strategy Call
      </PrimaryButton>
    </Box>
  );
};

export default Coaching;
