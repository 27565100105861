import * as amplitude from "@amplitude/analytics-browser";
import React, { useEffect } from "react";
import { useAuth } from "../../hooks/store/useAuth";
import { useNavigate } from "react-router-dom";
import { alpha, Box, Typography, useTheme } from "@mui/material";
import PrimaryButton from "../global/button/PrimaryButton";

const DiyContent = () => {
  const { userId } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    if (!userId) navigate("/");
  }, [userId, navigate]);

  return (
    <>
      <Typography
        sx={{
          marginTop: theme.margin.margin1,
          fontFamily: theme.typography.fontFamily2,
          fontSize: theme.typography.headingMain,
          color: theme.text.primary,
          fontWeight: theme.typography.heading1Bold,
        }}
      >
        We can speed your DIY
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: theme.margin.margin2,
          padding: theme.padding.padding2,
          background: alpha(theme.palette.background.primary, 0.1),
          borderRadius: "16px",
        }}
      >
        <Typography
          sx={{
            fontFamily: theme.typography.fontFamily2,
            fontSize: theme.typography.headingBasic,
            color: theme.text.basic,
            fontWeight: theme.typography.headingBasicBold,
          }}
        >
          Get free pointers
        </Typography>
        <Typography
          sx={{
            color: theme.text.basic,
            fontSize: { xs: "14px", sm: "16px" },
            marginTop: theme.margin.margin3,
            lineHeight: "18px",
          }}
        >
          Let’s pinpoint your 1 Small Change for Maximum Results.
        </Typography>
        <Typography
          sx={{
            color: theme.text.basic,
            fontSize: { xs: "14px", sm: "16px" },
            marginTop: theme.margin.margin2,
            lineHeight: "18px",
          }}
        >
          We’ll do this by understanding your:
        </Typography>
        <Box
          component={"ul"}
          sx={{
            listStylePosition: "outside",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
            marginLeft: "20px",
          }}
        >
          <Typography
            component={"li"}
            sx={{
              color: theme.text.basic,
              fontSize: { xs: "14px", sm: "16px" },
              marginTop: theme.margin.margin4,
              lineHeight: "16px",
            }}
          >
            <Typography
              component={"span"}
              sx={{
                fontWeight: theme.typography.headingMainBold,
                fontSize: { xs: "14px", sm: "16px" },
              }}
            >
              Body:
            </Typography>{" "}
            what is normal for you
          </Typography>
          <Typography
            component={"li"}
            sx={{
              color: theme.text.basic,
              fontSize: { xs: "14px", sm: "16px" },
              marginTop: theme.margin.margin4,
              lineHeight: "16px",
            }}
          >
            <Typography
              component={"span"}
              sx={{
                fontWeight: theme.typography.headingMainBold,
                fontSize: { xs: "14px", sm: "16px" },
              }}
            >
              Emotions:
            </Typography>{" "}
            your specific triggers and relationship with food
          </Typography>
          <Typography
            component={"li"}
            sx={{
              color: theme.text.basic,
              fontSize: { xs: "14px", sm: "16px" },
              marginTop: theme.margin.margin4,
              lineHeight: "16px",
            }}
          >
            <Typography
              component={"span"}
              sx={{
                fontWeight: theme.typography.headingMainBold,
                fontSize: { xs: "14px", sm: "16px" },
              }}
            >
              Beliefs:
            </Typography>{" "}
            what you believe about yourself and what’s real
          </Typography>
        </Box>
        <PrimaryButton
          component={"a"}
          href={
            "https://www.coachviva.com/resources/unstuck-call?utm_source=bfcalc"
          }
          target={"_blank"}
          sx={{ mt: "20px", mx: "auto", p: "24px", maxWidth: "320px" }}
          onClick={() => {
            amplitude.logEvent("ThankYou_CallClicked", {
              To: "SetterCall",
            });
          }}
        >
          Book Free UNStuck Strategy Call
        </PrimaryButton>
      </Box>
    </>
  );
};

export default DiyContent;
