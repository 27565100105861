import React, { useCallback, useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import CustomPagination from "./CustomPagination";
import useRealTimeData from "../../hooks/useRealTimeData"; // Importing useRealTimeData hook
import { useAuth } from "../../hooks/store/useAuth";
import { calculateBodyComposition } from "../../functions/bodyFatCalc";
import TableBody from "./TableBody";
import { formatFirebaseTimestamp } from "../../functions/formatFirebaseTimestamp";
import Skeleton from "../skeleton/Skeleton";
import { useCommon } from "../../hooks/store/useCommon";

const CheckInTable = () => {
  const { userId, sex } = useAuth();
  const { loading, data: rawData } = useRealTimeData(
    "/checkIns",
    "userId",
    "==",
    userId,
    "createdAt",
    "desc"
  );
  const [tableData, setTableData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(false);
  const [montFilter, setMonthFilter] = useState([]);
  const {
    setOpenUpdateCheckInDialog,
    openUpdateCheckInDialog,
    updateCheckInId,
  } = useCommon();

  // sync update record on actual data change
  useEffect(() => {
    if (openUpdateCheckInDialog && rawData) {
      const record = rawData?.find((record) => record?.id === updateCheckInId);
      return setOpenUpdateCheckInDialog(
        true,
        record?.id,
        record,
        rawData?.length
      );
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData, updateCheckInId, openUpdateCheckInDialog]);

  const fetchTableData = useCallback(() => {
    if (rawData) {
      let calculatedData = [];
      let monthFilterArray = [];
      rawData.forEach((record) => {
        let getMonthAndYear = formatFirebaseTimestamp(
          record?.createdAt?.seconds,
          "MMM YYYY"
        );
        if (!monthFilterArray.includes(getMonthAndYear)) {
          monthFilterArray.push(getMonthAndYear);
        }

        calculatedData.push({
          id: record.id,
          date: record.createdAt,
          weight: record.weight,
          ...calculateBodyComposition(
            record.weight,
            record.waist,
            record.neck,
            record.height,
            sex,
            record?.hip,
            "Fat Loss"
          ),
        });
      });
      setTableData(calculatedData);
      setCurrentMonth(monthFilterArray[0]);
      setMonthFilter(monthFilterArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData, sex]);

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  return (
    <Box
      sx={{
        mt: { xs: "18px", sm: "24px" },
        backgroundColor: "background.main",
        borderRadius: "6px",
      }}
    >
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: { xs: "unset", sm: "center" },
          gap: "12px",
        }}
      >
        {loading ? (
          <>
            <Skeleton
              sx={{
                maxWidth: "194px",
                width: "100%",
                height: "52px",
                alignSelf: "center",
              }}
            />
          </>
        ) : (
          <>
            <CustomPagination
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
              monthFilterArray={montFilter}
            />
          </>
        )}
      </Stack>

      <Box
        sx={{
          mt: { xs: "8px", sm: "16px" },
          borderRadius: "6px",
        }}
      >
        {loading ? (
          <>
            <Skeleton
              sx={{
                width: "100%",
                height: { xs: "370px", md: "76px" },
              }}
            />
            <Skeleton
              sx={{
                mt: "24px",
                width: "100%",
                height: { xs: "370px", md: "76px" },
              }}
            />
          </>
        ) : (
          <TableBody
            actualData={rawData}
            rowData={tableData}
            currentMonth={currentMonth}
          />
        )}
      </Box>
    </Box>
  );
};

export default CheckInTable;
